import useApp from "@/hooks/useApp";
import {
    deleteImageFromS3,
    getImageList,
    uploadImageToS3,
} from "@/lib/api/handlers/images";
import { extractResourceKeyFromUrl } from "@/lib/api/util";
import logger from "@/lib/logger";
import { useEffect, useState } from "react";
import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    Modal,
    Row,
    Spinner,
} from "react-bootstrap";

const strings = {
    title: "Manage Images",
    infoString: "Manage images for the application",
    addImage: "Add Image",
    copyUrl: "Copy URL",
    delete: "Delete",
    copiedToClipboard: "URL copied to clipboard!",
    deleteConfirmation: "Are you sure you want to delete this image?",
    imageDeleted: "Image deleted successfully!",
};

export default function ManageImagesPage() {
    const { imageList, setImageList } = useApp();
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [imageName, setImageName] = useState("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [toastVariant, setToastVariant] = useState<"success" | "danger">(
        "success",
    );
    const [toastMessage, setToastMessage] = useState("");

    useEffect(() => {
        if (imageList.length === 0) {
            loadImages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadImages = async () => {
        try {
            setIsLoading(true);
            const imageList = await getImageList("resource_images");
            imageList.shift();
            setImageList(imageList);
        } catch (error) {
            logger.error(`Error loading images: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyUrl = (url: string) => {
        navigator.clipboard.writeText(url);
        setToastVariant("success");
        setToastMessage(strings.copiedToClipboard);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    const handleDelete = async (imageTobeDeletedURL: string) => {
        if (window.confirm(strings.deleteConfirmation)) {
            try {
                await deleteImageFromS3(imageTobeDeletedURL);
                const newImageList = imageList.filter(
                    (listImgURL) => listImgURL !== imageTobeDeletedURL,
                );
                setImageList(newImageList);
                setToastVariant("danger");
                setToastMessage(strings.imageDeleted);
                setShowToast(true);
                setTimeout(() => setShowToast(false), 3000);
            } catch (error) {
                logger.error(`Error deleting image: ${error}`);
            }
        }
    };

    const handleUploadClick = () => {
        setShowUploadModal(true);
        setImageName("");
        setSelectedFile(null);
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (selectedFile && imageName) {
            const imageURL = await uploadImageToS3(
                selectedFile,
                imageName,
                false,
            );
            setImageList([...imageList, imageURL]);
            setShowUploadModal(false);
        }
    };

    return (
        <>
            <Container>
                <div className="d-flex justify-content-between align-items-center mb-5 mt-5">
                    <div>
                        <h2>{strings.title}</h2>
                        <p>{strings.infoString}</p>
                    </div>
                    <Button
                        variant="success"
                        className="float-end me-3"
                        onClick={handleUploadClick}
                    >
                        Add Image
                    </Button>
                </div>

                {showToast && (
                    <Alert
                        variant={toastVariant}
                        onClose={() => setShowToast(false)}
                        dismissible
                        className="mt-3"
                    >
                        {toastMessage}
                    </Alert>
                )}

                {isLoading ? (
                    <div
                        className="d-flex justify-content-center align-items-center "
                        style={{ maxHeight: "5px" }}
                    >
                        <Spinner />
                    </div>
                ) : (
                    <Row xs={1} md={2} lg={4} className="g-4">
                        {imageList.map((imageURL) => (
                            <Col key={imageURL}>
                                <Card>
                                    <Card.Img
                                        variant="top"
                                        src={imageURL}
                                        alt={imageURL}
                                        style={{
                                            height: "200px",
                                            objectFit: "contain",
                                            backgroundColor: "#f8f9fa",
                                            padding: "1rem",
                                        }}
                                    />
                                    <Card.Body>
                                        <Card.Title className="text-truncate">
                                            {extractResourceKeyFromUrl(imageURL)
                                                .split("/")
                                                .pop()}
                                        </Card.Title>
                                        <div className="d-flex gap-2">
                                            <Button
                                                variant="primary"
                                                onClick={() =>
                                                    handleCopyUrl(imageURL)
                                                }
                                            >
                                                {strings.copyUrl}
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={() =>
                                                    handleDelete(imageURL)
                                                }
                                            >
                                                {strings.delete}
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </Container>

            <Modal
                show={showUploadModal}
                onHide={() => setShowUploadModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Upload Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Image Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={imageName}
                                onChange={(e) => setImageName(e.target.value)}
                                placeholder="Enter image name"
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Select Image</Form.Label>
                            <div className="d-flex gap-2 align-items-center">
                                <Button
                                    variant="light"
                                    as="label"
                                    htmlFor="file-upload"
                                    style={{ cursor: "pointer" }}
                                >
                                    Choose File
                                </Button>
                                <span className="text-muted">
                                    {selectedFile
                                        ? selectedFile.name
                                        : "no file selected"}
                                </span>
                            </div>
                            <Form.Control
                                type="file"
                                id="file-upload"
                                className="d-none"
                                accept="image/*"
                                onChange={handleFileSelect}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowUploadModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleUpload}
                        disabled={!selectedFile || !imageName}
                    >
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
