import AddResourceModal from "@/components/AddResourceModal";
import ResourceList from "@/components/ResourceList";
import useApp from "@/hooks/useApp";
import { getImageList } from "@/lib/api/handlers/images";
import {
    getResourceMap,
    updateResourceMap,
} from "@/lib/api/handlers/resourceMaps";
import * as models from "@/lib/api/models";
import logger from "@/lib/logger";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

const strings = {
    title: "Manage Resources",
    noResources: "No resources available",
    infoString: "Move the boxes to re-arrange resources",
    saveResources: "Save Resource Ordering",
    addResource: "Add Resource",
    refetchResources: "Refetch Resources",
};

export default function ResourceReorderingPage() {
    const { resources, setResources, setImageList, setIconList } = useApp();
    const [updatedResources, setUpdatedResources] = useState<models.Resource[]>(
        [],
    );
    const navigate = useNavigate();
    const [showAddResourceModal, setShowAddResourceModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const initialFetchRef = useRef(true);

    const fetchResourceData = useCallback(async () => {
        try {
            setIsFetching(true);
            const validatedData = await getResourceMap();
            setResources(validatedData.resources);
            setUpdatedResources(validatedData.resources);
            const imageList = await getImageList("resource_images/");
            imageList.shift(); // Assuming shift is necessary
            setImageList(imageList);

            const iconList = await getImageList("icons/");
            iconList.shift(); // Assuming shift is necessary
            setIconList(iconList);
        } catch (error) {
            logger.log("Failed to get resources with: ", error);
        } finally {
            setIsFetching(false);
        }
    }, [setResources, setImageList, setIconList]);

    useEffect(() => {
        if (initialFetchRef.current) {
            initialFetchRef.current = false;
            fetchResourceData();
        }
    }, [fetchResourceData]);

    return (
        <Container>
            <Row className="mt-5">
                <Col md={4}>
                    <h2>{strings.title}</h2>
                    <p>{strings.infoString}</p>
                </Col>
                <Col md={1}></Col>
                <Col md={7} className="d-flex align-items-center gap-3">
                    <Button
                        className="flex-grow-1 p-2"
                        onClick={fetchResourceData}
                        disabled={isFetching}
                        variant="warning"
                    >
                        {strings.refetchResources}
                    </Button>
                    <Button
                        className="flex-grow-1 p-2"
                        variant="success"
                        onClick={() => {
                            setShowAddResourceModal(true);
                        }}
                    >
                        {strings.addResource}
                    </Button>
                    <Button
                        className="flex-grow-1 p-2"
                        style={{ minHeight: "41px" }}
                        onClick={() => {
                            setLoading(true);
                            updateResourceMap({ resources: resources })
                                .catch((err) => {
                                    logger.log(
                                        "Failed to save resource ordering with: ",
                                        err.message,
                                    );
                                })
                                .finally(() => {
                                    setLoading(false);
                                    setUpdatedResources(resources);
                                });
                        }}
                        disabled={
                            resources.length === 0 ||
                            resources === updatedResources
                        }
                    >
                        {loading ? (
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ maxHeight: "5px" }}
                            >
                                <Spinner />
                            </div>
                        ) : (
                            strings.saveResources
                        )}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3 mb-5">
                <Col>
                    {isFetching ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ maxHeight: "5px" }}
                        >
                            <Spinner />
                        </div>
                    ) : (
                        <ResourceList
                            resources={resources}
                            setResources={(updatedResources, isDeleting) => {
                                setResources(updatedResources);
                                if (isDeleting) {
                                    updateResourceMap({
                                        resources: updatedResources,
                                    }).catch((err) => {
                                        logger.log(
                                            "Failed to delete resource with: ",
                                            err.message,
                                        );
                                        alert("Failed to delete resource");
                                    });
                                }
                            }}
                            onModifyChildOrder={(resourceId) =>
                                navigate(`/resource-reordering/${resourceId}`)
                            }
                        />
                    )}
                </Col>
            </Row>
            <AddResourceModal
                showAddResourceModal={showAddResourceModal}
                setShowAddResourceModal={setShowAddResourceModal}
            />
        </Container>
    );
}
