import { getLinkToCreateS3File } from "@/lib/api/handlers/resource";
import * as models from "../models";
import * as util from "../util";

export async function getImageList(prefix: string): Promise<models.ImageList> {
    const params = new URLSearchParams({
        prefix,
    });

    const [status, response] = await util.apiRequest({
        path: "/admin/resource-image-list?" + params,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer QWxhZGRpbjpvcGVuIHNlc2FtZQ==`,
        },
    });

    return util.parseResponse(status, response, models.imageList);
}

export async function uploadImageToS3(
    file: File,
    fileName: string,
    isIcon?: boolean,
): Promise<string> {
    const cleanedFileName = fileName
        .trim()
        .replace(/[^a-z0-9]/gi, "-")
        .toLowerCase();
    const prefix = isIcon ? "icons" : "resource_images";
    const fileS3Key = `${prefix}/${cleanedFileName}-${util.generateUniqueId()}.${
        file.type.split("/")[1]
    }`;

    try {
        const s3Link = await getLinkToCreateS3File(fileS3Key);
        const response = await fetch(s3Link, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Type": file.type,
            },
        });

        if (!response.ok) {
            throw new Error(
                `Failed to upload image to S3 with status code ${response.status}`,
            );
        }

        const baseURL = util.extractBaseUrl(response.url);

        return `${baseURL}/${fileS3Key}`;
    } catch (err) {
        const error = err as Error;
        throw new Error(
            `Failed to upload image in uploadImageToS3: ${error.message}`,
        );
    }
}

export async function deleteImageFromS3(imageURL: string): Promise<void> {
    const fileS3Key = util.extractResourceKeyFromUrl(imageURL);

    const params = new URLSearchParams({
        key: fileS3Key,
    });

    const [status, response] = await util.apiRequest({
        path: "/admin/resource-image?" + params,
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer QWxhZGRpbjpvcGVuIHNlc2FtZQ==`,
        },
    });

    if (status !== 200) {
        throw new Error(
            `Failed to delete from S3 with status code ${status} and response ${response}`,
        );
    }
}
