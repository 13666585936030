import * as models from "@/lib/api/models";
import React, { createContext, useState } from "react";

export type AuthContextType = {
    me?: models.Account;
    signIn: (me: models.Account) => void;
    signOut: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<AuthContextType>(null!);

type Props = {
    children: React.ReactNode;
};

export function AuthProvider(props: Props) {
    const [me, setMe] = useState<models.Account | undefined>(undefined);

    function signIn(me: models.Account) {
        setMe(me);
    }

    function signOut() {
        setMe(undefined);
    }

    const value: AuthContextType = {
        me,
        signIn,
        signOut,
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    );
}
