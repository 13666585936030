import * as models from "@/lib/api/models";

import AddResourceModal from "@/components/AddResourceModal";
import ResourceList from "@/components/ResourceList";
import useApp from "@/hooks/useApp";
import { updateResourceMap } from "@/lib/api/handlers/resourceMaps";
import logger from "@/lib/logger";
import { useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

const strings = {
    title: (parentName: string): string =>
        `Directory Resources for ${parentName}`,
    noChildResources: "No child resources available",
    infoString: "Move the boxes to re-arrange sub-resources for this resource",
    backButtonLabel: "Back to Resources",
    addResource: "Add Resource",
    saveResources: "Save Resource Ordering",
};

export default function ChildResourceReorderingPage() {
    const navigate = useNavigate();
    const { id = "" } = useParams<Params>();
    const { resources, setResources } = useApp();
    const parentResource = resources.find((resource) => resource.id === id);
    const directoryResources = parentResource?.resources;

    const [showAddResourceModal, setShowAddResourceModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const setDirectoryResources = (
        newResources: models.DirectoryResource[],
        isDeleting?: boolean,
    ) => {
        const resourceCopy = [...resources];
        const resourceIndex = resourceCopy.findIndex(
            (resource) => resource.id === id,
        );
        if (resourceIndex === -1) {
            return;
        }

        resourceCopy[resourceIndex] = {
            ...resourceCopy[resourceIndex],
            resources: newResources,
        };
        setResources(resourceCopy);
        if (isDeleting) {
            updateResourceMap({
                resources: resourceCopy,
            }).catch((err) => {
                logger.log("Failed to delete sub resource with: ", err.message);
                alert("Failed to delete resource");
            });
        }
    };

    if (!parentResource || !directoryResources) {
        return null;
    }

    return (
        <Container>
            <Row className="mt-5">
                <Col md={9}>
                    <h2>{strings.title(parentResource.title.en)} </h2>
                </Col>
                <Col
                    md={3}
                    className="d-flex align-items-center justify-content-end"
                >
                    <Button
                        variant="link"
                        onClick={() => navigate(`/resource-reordering`)}
                        className="button-primary text-decoration-none"
                    >
                        {strings.backButtonLabel}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={6}>
                    <p>{strings.infoString}</p>
                </Col>
                <Col
                    md={3}
                    className="d-flex align-items-center justify-content-between"
                >
                    <Button
                        className="w-100 p-2"
                        variant="success"
                        onClick={() => {
                            setShowAddResourceModal(true);
                        }}
                    >
                        {strings.addResource}
                    </Button>
                </Col>
                <Col
                    md={3}
                    className="d-flex align-items-center justify-content-between"
                >
                    <Button
                        className="w-100 p-2"
                        style={{ minHeight: "41px" }}
                        onClick={() => {
                            setLoading(true);
                            updateResourceMap({ resources: resources })
                                .catch((err) => {
                                    logger.log(
                                        "Failed to save resource ordering with: ",
                                        err.message,
                                    );
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                        disabled={directoryResources.length === 0}
                    >
                        {loading ? (
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ maxHeight: "5px" }}
                            >
                                <Spinner />
                            </div>
                        ) : (
                            strings.saveResources
                        )}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <ResourceList
                        resources={directoryResources}
                        setResources={setDirectoryResources}
                    />
                </Col>
            </Row>
            <AddResourceModal
                showAddResourceModal={showAddResourceModal}
                setShowAddResourceModal={setShowAddResourceModal}
                parentResource={parentResource}
            />
        </Container>
    );
}
