import App from "@/components/App";
import AuthLayout from "@/components/layouts/AuthLayout";
import UnauthLayout from "@/components/layouts/UnauthLayout";
import ChildResourceReorderingPage from "@/components/pages/ChildResourceReorderingPage";
import EditResourcePage from "@/components/pages/EditResourcePage";
import ErrorPage from "@/components/pages/ErrorPage";
import LoginPage from "@/components/pages/LoginPage";
import ManageIconsPage from "@/components/pages/ManageIconsPage";
import ManageImagesPage from "@/components/pages/ManageImagesPage";
import ResourceReorderingPage from "@/components/pages/ResourceReorderingPage";
import RedirectHandler from "@/components/RedirectHandler";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "",
                element: <RedirectHandler />,
            },
            {
                path: "connect",
                element: <UnauthLayout />,
                children: [
                    {
                        path: "*",
                        element: <LoginPage />,
                    },
                ],
            },
            {
                path: "",
                element: <AuthLayout />,
                children: [
                    {
                        path: "resource-reordering",
                        element: <ResourceReorderingPage />,
                    },
                    {
                        path: "resource-reordering/:id",
                        element: <ChildResourceReorderingPage />,
                    },
                    {
                        path: "edit-resource/:id",
                        element: <EditResourcePage />,
                    },
                    {
                        path: "manage-images",
                        element: <ManageImagesPage />,
                    },
                    {
                        path: "manage-icons",
                        element: <ManageIconsPage />,
                    },
                ],
            },
        ],
    },
]);

export default router;
