import * as models from "@/lib/api/models";

import { createNewResource } from "@/lib/api/handlers/resource";
import { extractResourceKeyFromUrl, generateUniqueId } from "@/lib/api/util";
import React, { ReactNode, useState } from "react";

import useApp from "@/hooks/useApp";
import { updateResourceMap } from "@/lib/api/handlers/resourceMaps";
import logger from "@/lib/logger";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

type Props = {
    showAddResourceModal: boolean;
    setShowAddResourceModal: (show: boolean) => void;
    parentResource?: models.Resource;
};

const strings = {
    enTitleLabel: "English Title",
    spTitleLabel: "Spanish Title",
    resourceTypeLabel: "Resource Type",
    iconFormLabel: "Resource Icon",
    selectIconLabel: "Select Icon",
    addNewResource: "Add New Resource",
    closeButtonLabel: "Close",
    saveChangesButtonLabel: "Save Changes",
    errorMessage: (errorMessage: string) =>
        `Failed to add new resource with the error message: ${errorMessage}`,
    errorMessageAction: "Would you like to try again?",
    tryAgainButtonLabel: "Try Again",
};

export default function AddResourceModal({
    showAddResourceModal,
    setShowAddResourceModal,
    parentResource,
}: Props) {
    const { resources, setResources, iconList } = useApp();
    const [newResource, setNewResource] =
        useState<models.NewResourceRequest>(emptyResource);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const renderModalBody = () => {
        if (loading) {
            return (
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <Spinner />
                </div>
            );
        }

        if (error) {
            return (
                <div className="d-flex flex-column gap-3">
                    <span>{strings.errorMessage(error)}</span>
                    <span>{strings.errorMessageAction}</span>
                    <Button
                        variant="warning"
                        onClick={() => {
                            setError(null);
                        }}
                        className="align-self-center"
                    >
                        {strings.tryAgainButtonLabel}
                    </Button>
                </div>
            );
        }

        return (
            <>
                <Form>
                    <Form.Group>
                        <Form.Label>{strings.enTitleLabel}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newResource?.title.en}
                            onChange={(e) =>
                                setNewResource({
                                    ...newResource,
                                    title: {
                                        ...newResource?.title,
                                        en: e.target.value,
                                    },
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label>{strings.spTitleLabel}</Form.Label>
                        <Form.Control
                            type="text"
                            value={newResource?.title.sp}
                            onChange={(e) =>
                                setNewResource({
                                    ...newResource,
                                    title: {
                                        ...newResource?.title,
                                        sp: e.target.value,
                                    },
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label>{strings.resourceTypeLabel}</Form.Label>
                        <Form.Control
                            as="select"
                            value={newResource?.type ?? undefined}
                            onChange={(e) => {
                                if (e.target.value !== "none") {
                                    setNewResource({
                                        ...newResource,
                                        type: e.target
                                            .value as models.ResourceType,
                                    });
                                } else {
                                    setNewResource({
                                        ...newResource,
                                        type: null,
                                    });
                                }
                            }}
                        >
                            <option value={"none"}>Select Type</option>
                            <option value={"article"}>Article</option>
                            {!parentResource && (
                                <option value={"group"}>Group</option>
                            )}
                        </Form.Control>
                    </Form.Group>
                </Form>
                <div className="mt-3">
                    <span>{strings.iconFormLabel}</span>
                    <div className="d-flex align-items-center gap-5 mt-1 p-2 border rounded">
                        <Dropdown
                            id="dropdown-item-button"
                            title="Select Icon For Resource"
                        >
                            <Dropdown.Toggle
                                as={CustomToggle}
                                id="dropdown-custom-components"
                            >
                                {strings.selectIconLabel}
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu}>
                                {iconList.map((icon) => {
                                    return (
                                        <Dropdown.Item
                                            key={icon}
                                            onClick={() => {
                                                setNewResource({
                                                    ...newResource,
                                                    icon: icon,
                                                });
                                            }}
                                        >
                                            <img src={icon} width={20} />
                                            <span className="ps-4">
                                                {extractResourceKeyFromUrl(
                                                    icon,
                                                    7,
                                                )}
                                            </span>
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        {newResource.icon && (
                            <div>
                                <img src={newResource.icon} width={20} />
                                <span className="ps-4">
                                    {extractResourceKeyFromUrl(
                                        newResource.icon,
                                        7,
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    async function onCreateNewResource() {
        setLoading(true);

        try {
            const updatedResourceMap: models.Resource[] = [...resources];

            if (!parentResource && newResource.type === "group") {
                const newGroupResource: models.Resource = {
                    id: generateUniqueId(),
                    title: newResource.title,
                    icon: newResource.icon,
                    type: "group",
                    resources: [],
                };
                updatedResourceMap.push(newGroupResource);
            } else {
                const completeNewResource = await createNewResource(
                    newResource,
                );

                if (!parentResource && completeNewResource.type === "article") {
                    const newResource: models.Resource = {
                        id: completeNewResource.id,
                        title: completeNewResource.title,
                        icon: completeNewResource.icon,
                        type: "article",
                        contentUrl: completeNewResource.contentUrl,
                    };
                    updatedResourceMap.push(newResource);
                }

                if (parentResource && completeNewResource.type === "article") {
                    const parentResourceIndex = updatedResourceMap.findIndex(
                        (resource) => resource.id === parentResource.id,
                    );
                    if (parentResourceIndex !== -1) {
                        const newSubResource: models.DirectoryResource = {
                            id: completeNewResource.id,
                            title: completeNewResource.title,
                            icon: completeNewResource.icon,
                            type: "article",
                            contentUrl: completeNewResource.contentUrl,
                        };
                        updatedResourceMap[parentResourceIndex] = {
                            ...parentResource,
                            resources: [
                                ...(parentResource.resources ?? []),
                                newSubResource,
                            ],
                        };
                    }
                }
            }

            await updateResourceMap({
                resources: updatedResourceMap,
            }); //update on s3
            setResources(updatedResourceMap); //update on local state

            setShowAddResourceModal(false);
            setNewResource(emptyResource);
        } catch (err) {
            const error = err as Error;
            logger.warn(error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }
    return (
        <Modal
            show={showAddResourceModal}
            onHide={() => {
                setShowAddResourceModal(false);
                setNewResource(emptyResource);
            }}
            className="mt-5 pt-5"
        >
            <Modal.Header closeButton>
                <Modal.Title>{strings.addNewResource}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{renderModalBody()}</Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setShowAddResourceModal(false);
                        setNewResource(emptyResource);
                    }}
                    disabled={loading || !!error}
                >
                    {strings.closeButtonLabel}
                </Button>
                <Button
                    variant="primary"
                    disabled={
                        !newResource.title.en ||
                        !newResource.title.sp ||
                        !newResource.type ||
                        !newResource.icon ||
                        loading ||
                        !!error
                    }
                    onClick={async () => {
                        onCreateNewResource();
                    }}
                >
                    {strings.saveChangesButtonLabel}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const emptyResource: models.NewResourceRequest = {
    title: {
        en: "",
        sp: "",
    },
    icon: "",
    type: null,
};

interface CustomToggleProps {
    children: ReactNode;
    onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const CustomToggle = React.forwardRef<HTMLAnchorElement, CustomToggleProps>(
    ({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            className="d-flex align-items-center text-decoration-none"
        >
            {children}
            <span className="ms-2">&#x25bc;</span>
        </a>
    ),
);

interface CustomMenuProps {
    children: ReactNode;
    style: React.CSSProperties;
    className: string;
    "aria-labelledby": string;
}

const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        return (
            <div
                ref={ref}
                style={{ ...style, maxHeight: "600px", overflowY: "auto" }}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled">
                    {React.Children.toArray(children)}
                </ul>
            </div>
        );
    },
);
