import * as account from "@/lib/api/handlers/account";

import RequireAuth from "@/components/RequireAuth";
import useAuth from "@/hooks/useAuth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const strings = {
    header: "Texas Quitline Admin Interface",
    logout: "Logout",
    pageTitle: "TX Quitline Admin Interface",
};

type NavLink = {
    href: string;
    label: string;
};

const navLinks: NavLink[] = [
    {
        href: "/resource-reordering",
        label: "Manage Resources",
    },
    {
        href: "/manage-images",
        label: "Manage Images",
    },
    {
        href: "/manage-icons",
        label: "Manage Icons",
    },
];

export default function AuthLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    function goTo(e: React.MouseEvent<HTMLElement>, destination: string) {
        e.preventDefault();
        navigate(destination);
    }

    async function logout() {
        await account.logout();
        auth.signOut();
        navigate("/");
    }

    return (
        <RequireAuth>
            <Navbar data-bs-theme="dark">
                <Container>
                    <Navbar.Brand>{strings.pageTitle}</Navbar.Brand>
                    <Nav className="me-auto" activeKey={location.pathname}>
                        {navLinks.map((link) => (
                            <Nav.Link
                                key={link.href}
                                href={link.href}
                                onClick={(e) => goTo(e, link.href)}
                            >
                                {link.label}
                            </Nav.Link>
                        ))}
                    </Nav>

                    <Nav activeKey={location.pathname}>
                        <Nav.Link href="/" onClick={() => logout()}>
                            {strings.logout}
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <Container>
                <Outlet />
            </Container>
        </RequireAuth>
    );
}
