import { generateUniqueId } from "@/lib/api/util";
import { z } from "zod";

// Existing models
export const account = z.object({
    id: z.number(),
    username: z.string(),
    email: z.string().nullish(),
    role: z.enum(["admin", "customer"]),
    token: z.string().nullish(),
});

export const loginRequest = z.object({
    username: z.string(),
    password: z.string(),
});

export const validationErrors = z.record(z.string(), z.string().array());

export const validationResponse = z.object({
    errors: validationErrors,
});

export const zodUnknown = z.unknown();

export type Account = z.infer<typeof account>;
export type LoginRequest = z.infer<typeof loginRequest>;
export type ValidationErrors = z.infer<typeof validationErrors>;
export type ValidationResponse = z.infer<typeof validationResponse>;

// Custom Models

function useValueOrGenerateUnique(str: string | undefined): string {
    return str ?? generateUniqueId();
}

export const localizedString = z.object({
    en: z.string(),
    sp: z.string(),
});

export const resourceType = z.enum(["article", "group"]);

export const directoryResource = z.object({
    id: z.string().optional().transform(useValueOrGenerateUnique),
    title: localizedString,
    icon: z.string(),
    type: z.literal(resourceType.enum.article),
    contentUrl: localizedString,
});

export const resource = z.object({
    id: z.string().optional().transform(useValueOrGenerateUnique),
    title: localizedString,
    icon: z.string(),
    type: resourceType,
    contentUrl: localizedString.optional(),
    resources: z.array(directoryResource).nullable().optional(),
});

export const resourceResponse = z.object({
    resources: z.array(resource),
});

export const directoryResourceWithoutId = z.object({
    title: localizedString,
    icon: z.string(),
    type: z.literal(resourceType.enum.article),
    contentUrl: localizedString,
});

export const resourceWithoutId = z.object({
    title: localizedString,
    icon: z.string(),
    type: resourceType,
    contentUrl: localizedString.optional(),
    resources: z.array(directoryResourceWithoutId).nullable().optional(),
});

export const resourceRequest = z.object({
    resources: z.array(resourceWithoutId),
});

export const language = z.enum(["en", "sp"]);

export const imageList = z.array(z.string());
export const iconList = z.array(z.string());

export const newResourceRequest = z.object({
    title: localizedString,
    type: resourceType.nullable(),
    icon: z.string(),
});

export const newResourceResponse = z.object({
    id: z.string(),
    title: localizedString,
    type: resourceType,
    icon: z.string(),
    contentUrl: localizedString,
});

export const logoutResponse = z.object({
    message: z.string(),
});

export type LocalizedString = z.infer<typeof localizedString>;
export type ResourceType = z.infer<typeof resourceType>;
export type DirectoryResource = z.infer<typeof directoryResource>;
export type Resource = z.infer<typeof resource>;
export type ResourceResponse = z.infer<typeof resourceResponse>;
export type Language = z.infer<typeof language>;
export type ImageList = z.infer<typeof imageList>;
export type IconList = z.infer<typeof iconList>;
export type NewResourceRequest = z.infer<typeof newResourceRequest>;
export type NewResourceResponse = z.infer<typeof newResourceResponse>;

export type DirectoryResourceWithoutId = z.infer<
    typeof directoryResourceWithoutId
>;
export type ResourceWithoutId = z.infer<typeof resourceWithoutId>;
export type ResourceRequest = z.infer<typeof resourceRequest>;
