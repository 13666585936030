import logger from "@/lib/logger";
import { z } from "zod";
import * as errors from "./errors";
import * as models from "./models";

export function apiUrl(path: string): string {
    return import.meta.env.VITE_API_BASE_URL + path;
}

export type RequestCfg = {
    path: string;
    method: "get" | "post" | "put" | "patch" | "delete";
    data?: string;
    headers?: Record<string, string>;
};

export async function apiRequest(cfg: RequestCfg): Promise<[number, unknown]> {
    const url = apiUrl(cfg.path);
    const response = await fetch(url, {
        method: cfg.method,
        body: cfg.data,
        headers: cfg.headers,
    });

    let body: unknown;
    if (response.headers.get("content-type")?.startsWith("application/json")) {
        body = await response.json();
    } else {
        body = await response.text();
    }

    return [response.status, body];
}

export async function parseResponse<InputT, OutputT>(
    respStatus: number,
    respBody: unknown,
    model: z.ZodType<OutputT, z.ZodTypeDef, InputT>,
): Promise<OutputT> {
    if (respStatus < 200 || respStatus > 299) {
        // Got a non-success status code back. Try parsing as validation response
        const validation = models.validationResponse.safeParse(respBody);
        if (validation.success) {
            const msg = `Received validation response with status code ${respStatus} in failed API call`;
            throw new errors.APIValidationError(msg, validation.data);
        }

        // We have an error status code and a json response that isn't a validation message.
        throw new errors.APIHTTPError(
            `Received text response with status code ${respStatus} in failed API call`,
            respStatus,
            respBody,
        );
    }

    return model.parse(respBody);
}

export function extractResourceKeyFromUrl(
    url: string,
    substringIdx = 1,
): string {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.pathname.substring(substringIdx);
    } catch (error) {
        logger.error(`Invalid URL: ${error}`);
        return "";
    }
}

export function generateUniqueId(length = 8): string {
    const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        result += chars[randomIndex];
    }

    return result;
}

export function extractBaseUrl(url: string): string {
    const parsedUrl = new URL(url);
    return parsedUrl.origin;
}
