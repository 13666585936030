import useAuth from "@/hooks/useAuth";
import * as account from "@/lib/api/handlers/account";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const strings = {
    loading: "Checking if authenticated...",
};

export default function App() {
    const auth = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        account
            .me()
            .then((acct) => {
                auth.signIn(acct);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{loading ? strings.loading : <Outlet />}</>;
}
