import * as models from "./models";

export class APIValidationError extends Error {
    message: string;
    validation: models.ValidationResponse;

    constructor(message: string, validation: models.ValidationResponse) {
        super(message);
        this.message = message;
        this.validation = validation;
    }
}

export class APIHTTPError extends Error {
    message: string;
    responseStatus: number;
    responseBody: unknown;

    constructor(
        message: string,
        responseStatus: number,
        responseBody: unknown,
    ) {
        super(message);
        this.message = message;
        this.responseStatus = responseStatus;
        this.responseBody = responseBody;
    }
}
