import * as errors from "../errors";
import * as models from "../models";
import * as util from "../util";

export async function getResourceMap(): Promise<models.ResourceResponse> {
    const [URLstatus, URLresponse] = await util.apiRequest({
        path: "/admin/resource-map/presigned-get",
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer QWxhZGRpbjpvcGVuIHNlc2FtZQ==`,
        },
    });

    if (URLstatus !== 200) {
        throw new errors.APIHTTPError(
            `Failed getting presigned GET URL from S3 with status code ${URLstatus} in failed API call`,
            URLstatus,
            URLresponse,
        );
    }

    const s3Link = URLresponse as string;

    const response = await fetch(s3Link, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error(
            `Failed to fetch resource from S3: ${response.statusText}`,
        );
    }

    const respBody = await response.json();

    return util.parseResponse(
        response.status,
        respBody,
        models.resourceResponse,
    );
}

function stripIdFromResources(
    response: models.ResourceResponse,
): models.ResourceRequest {
    const cleanedResources: models.ResourceWithoutId[] = [];

    response.resources.forEach((resource) => {
        const cleanedResource = resource as models.ResourceWithoutId;

        if (cleanedResource.resources) {
            const cleanedSubResources =
                cleanedResource.resources as models.DirectoryResourceWithoutId[];
            cleanedResource.resources = cleanedSubResources;
        }

        cleanedResources.push(cleanedResource);
    });

    return {
        resources: cleanedResources,
    };
}

export async function updateResourceMap(
    resourceMap: models.ResourceResponse,
): Promise<void> {
    const [URLstatus, URLresponse] = await util.apiRequest({
        path: "/admin/resource-map/presigned-put",
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer QWxhZGRpbjpvcGVuIHNlc2FtZQ==`,
        },
    });

    if (URLstatus !== 200) {
        throw new errors.APIHTTPError(
            `Failed getting presigned PUT URL from S3 with status code ${URLstatus} in failed API call`,
            URLstatus,
            URLresponse,
        );
    }

    const s3Link = URLresponse as string;

    const updateResponse = await fetch(s3Link, {
        method: "PUT",
        body: JSON.stringify(stripIdFromResources(resourceMap)),
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!updateResponse.ok) {
        throw new Error(
            `Failed to update resource map: ${updateResponse.statusText}`,
        );
    }
}
