import "bootstrap/dist/css/bootstrap.min.css";

import { AppProvider } from "@/components/AppContext.tsx";
import { AuthProvider } from "@/components/AuthContext";
import "@/css/index.css";
import router from "@/router";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

async function prepare(): Promise<unknown> {
    if (
        import.meta.env.DEV &&
        import.meta.env.VITE_ENABLE_MOCK_API === "true"
    ) {
        const { worker } = await import("./lib/api/mocks/browser.ts");
        return worker.start({
            onUnhandledRequest(req, print) {
                if (
                    import.meta.env.VITE_API_BASE_URL &&
                    new URL(req.url).pathname.startsWith(
                        import.meta.env.VITE_API_BASE_URL,
                    )
                ) {
                    print.warning();
                }
            },
        });
    }

    return Promise.resolve();
}

prepare().then(() => {
    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
        <React.StrictMode>
            <AuthProvider>
                <AppProvider>
                    <RouterProvider router={router} />
                </AppProvider>
            </AuthProvider>
        </React.StrictMode>,
    );
});
