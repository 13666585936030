import * as models from "../models";
import * as util from "../util";

export async function me(): Promise<models.Account> {
    const [respStatus, respBody] = await util.apiRequest({
        path: "/admin/account/me",
        method: "get",
    });

    return await util.parseResponse(respStatus, respBody, models.account);
}

export async function login(req: models.LoginRequest): Promise<models.Account> {
    const [respStatus, respBody] = await util.apiRequest({
        path: "/admin/account/login",
        method: "post",
        data: JSON.stringify(req),
        headers: {
            "Content-Type": "application/json",
        },
    });

    const account = await util.parseResponse(
        respStatus,
        respBody,
        models.account,
    );

    // Store the token in localStorage after successful login
    if (account.token) {
        localStorage.setItem("auth_token", account.token);
    }

    return account;
}

export async function logout(): Promise<{ message: string }> {
    const [respStatus, respBody] = await util.apiRequest({
        path: "/admin/account/logout",
        method: "post",
    });

    // Clear the token after logout
    localStorage.removeItem("auth_token");

    // Update the return type to match the API response
    return await util.parseResponse(
        respStatus,
        respBody,
        models.logoutResponse,
    );
}
