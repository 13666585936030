import * as account from "@/lib/api/handlers/account";
import * as models from "@/lib/api/models";

import styles from "@/css/LoginPage.module.css";
import useAuth from "@/hooks/useAuth";
import { APIValidationError } from "@/lib/api/errors";
import logger from "@/lib/logger";
import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

const strings = {
    title: "Texas Quitline Admin",
    email: "Email",
    password: "Password",
    submit: "Submit",
    loading: "Loading",
};

export default function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState<models.ValidationErrors>({});

    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();
    const from = location.state?.from?.pathname || "/";
    const validationKeys = Object.keys(validation);

    async function login(e: FormEvent) {
        e.preventDefault();
        if (loading) {
            return;
        }

        setLoading(true);
        setValidation({});

        try {
            const acct = await account.login({
                username: username,
                password: password,
            });

            auth.signIn(acct);
            navigate(from, { replace: true });
        } catch (err) {
            if (err instanceof APIValidationError) {
                setValidation(err.validation.errors);
            } else {
                logger.warn(err);
            }

            setLoading(false);
        }
    }

    return (
        <Container className="d-flex align-items-center justify-content-center min-vh-100">
            <div className="w-100" style={{ maxWidth: "400px" }}>
                <div className="card shadow-sm">
                    <div className="card-body p-4">
                        <form onSubmit={login}>
                            <div className="text-center mb-4">
                                <h2 className="fw-bold mb-2">
                                    {strings.title}
                                </h2>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">
                                    {strings.email}
                                </label>
                                <input
                                    className="form-control"
                                    disabled={loading}
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    required={true}
                                    placeholder="Enter your email"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="form-label">
                                    {strings.password}
                                </label>
                                <input
                                    className="form-control"
                                    disabled={loading}
                                    type="password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    required={true}
                                    placeholder="Enter your password"
                                />
                            </div>

                            <Button
                                className="w-100 btn-lg"
                                variant="primary"
                                disabled={loading}
                                type="submit"
                            >
                                {loading ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        {strings.loading}
                                    </>
                                ) : (
                                    strings.submit
                                )}
                            </Button>

                            {validationKeys.length > 0 && (
                                <div className="mt-3">
                                    <ul
                                        className={`${styles.validationMessages} list-unstyled mb-0`}
                                    >
                                        {validationKeys.map((k) => (
                                            <li
                                                key={k}
                                                className="text-danger small"
                                            >
                                                {validation[k].join(". ")}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
}
