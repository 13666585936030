import * as models from "@/lib/api/models";

import useResourceById from "@/hooks/useResourceById";
import { getResource, updateResource } from "@/lib/api/handlers/resource";
import { extractResourceKeyFromUrl } from "@/lib/api/util";
import MDEditor from "@uiw/react-md-editor";
import { useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";

import logger from "@/lib/logger";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const strings = {
    title: "Edit Resource",
    languageLabel: "Language",
    saveButtonLabel: "Save",
    markdownSaved: "Markdown Saved",
    englishRadioLabel: "English",
    spanishRadioLabel: "Spanish",
    resourceNotFound: "Resource not found",
    savingFailed: "Saving markdown failed",
};
export default function EditResourcePage() {
    const { id = "" } = useParams<Params>();
    const currentResource = useResourceById(id);
    const [markdown, setMarkdown] = useState<models.LocalizedString>({
        en: "",
        sp: "",
    });
    const [currentLanguage, setCurrentLanguage] =
        useState<models.Language>("en");

    useEffect(() => {
        if (currentResource) {
            getResource(
                extractResourceKeyFromUrl(currentResource.contentUrl.en),
            )
                .then((resourceText) => {
                    setMarkdown((existingMarkdown) => {
                        return {
                            ...existingMarkdown,
                            en: resourceText,
                        };
                    });
                })
                .catch(() => {
                    return "";
                });
        }
    }, [currentResource]);

    useEffect(() => {
        if (currentResource) {
            getResource(
                extractResourceKeyFromUrl(currentResource.contentUrl.sp),
            )
                .then((resourceText) => {
                    setMarkdown((existingMarkdown) => {
                        return {
                            ...existingMarkdown,
                            sp: resourceText,
                        };
                    });
                })
                .catch(() => {
                    return "";
                });
        }
    }, [currentResource]);

    if (!currentResource || !markdown) {
        return <div>{strings.resourceNotFound}</div>;
    }

    return (
        <Container className="mt-5">
            <Row>
                <Col md={{ span: 4 }}>
                    <h2>{strings.title}</h2>
                </Col>
                <Col
                    md={{ span: 4, offset: 4 }}
                    className="d-flex mb-1 align-item-center justify-content-end"
                >
                    <h4 className="me-3 mt-1 mb-0">{strings.languageLabel}</h4>
                    <Form className="d-flex align-items-center justify-content-center">
                        <Form.Check
                            inline
                            label={strings.englishRadioLabel}
                            name="group1"
                            type="radio"
                            id={`inline-radio-en`}
                            onChange={() => setCurrentLanguage("en")}
                            checked={currentLanguage === "en"}
                        />
                        <Form.Check
                            inline
                            label={strings.spanishRadioLabel}
                            name="group1"
                            type="radio"
                            id={`inline-radio-es`}
                            className="me-0"
                            onChange={() => setCurrentLanguage("sp")}
                            checked={currentLanguage === "sp"}
                        />
                    </Form>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={10}>
                    <h3>{currentResource.title[currentLanguage]}</h3>
                </Col>

                <Col md={2}>
                    <Button
                        className="w-100"
                        onClick={() => {
                            updateResource(
                                extractResourceKeyFromUrl(
                                    currentResource.contentUrl[currentLanguage],
                                ),
                                markdown[currentLanguage],
                            )
                                .then(() => {
                                    alert(strings.markdownSaved);
                                })
                                .catch((err) => {
                                    logger.error(
                                        `Saving markdown failed with ${err}`,
                                    );
                                    alert(strings.savingFailed);
                                });
                        }}
                    >
                        {strings.saveButtonLabel}
                    </Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col data-color-mode="light">
                    <MDEditor
                        value={markdown[currentLanguage]}
                        onChange={(val) => {
                            if (val) {
                                setMarkdown((existingMarkdown) => {
                                    return {
                                        ...existingMarkdown,
                                        [currentLanguage]: val,
                                    };
                                });
                            }
                        }}
                        height="700px"
                    />
                </Col>
            </Row>
        </Container>
    );
}
