import * as models from "@/lib/api/models";

import React, { createContext, useState } from "react";

export type AppContextType = {
    resources: models.Resource[];
    setResources: (resources: models.Resource[]) => void;
    imageList: models.ImageList;
    setImageList: (imageList: models.ImageList) => void;
    iconList: models.IconList;
    setIconList: (iconList: models.IconList) => void;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppContext = createContext<AppContextType>(null!);

type Props = {
    children: React.ReactNode;
};

export function AppProvider(props: Props) {
    const [resources, setResources] = useState<models.Resource[]>([]);
    const [imageList, setImageList] = useState<models.ImageList>([]);
    const [iconList, setIconList] = useState<models.IconList>([]);

    const value: AppContextType = {
        resources: resources,
        setResources: (resources?: models.Resource[]) => {
            setResources(resources ?? []);
        },
        imageList: imageList,
        setImageList: (imageList?: models.ImageList) => {
            setImageList(imageList ?? []);
        },
        iconList: iconList,
        setIconList: (iconList?: models.IconList) => {
            setIconList(iconList ?? []);
        },
    };

    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    );
}
