import { Outlet } from "react-router-dom";

import Container from "react-bootstrap/Container";

export default function UnauthLayout() {
    return (
        <Container>
            <Outlet />
        </Container>
    );
}
