import { isRouteErrorResponse, useRouteError } from "react-router-dom";

const strings = {
    unknown: "An unexpected error has occurred.",
};

export default function ErrorPage() {
    const err = useRouteError();
    let description = strings.unknown;
    if (isRouteErrorResponse(err)) {
        description = `${err.status} ${err.statusText}`;
    }

    return <div>{description}</div>;
}
