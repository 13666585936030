import useAuth from "@/hooks/useAuth";
import { Navigate } from "react-router-dom";

export default function RedirectHandler() {
    const auth = useAuth();
    return auth ? (
        <Navigate to="/resource-reordering" replace />
    ) : (
        <Navigate to="/connect" replace />
    );
}
