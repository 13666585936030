import useApp from "@/hooks/useApp";
import * as models from "@/lib/api/models";

export default function useResourceById(
    id: string,
): models.DirectoryResource | null {
    const { resources } = useApp();

    for (const resource of resources) {
        if (resource.id === id && resource.type == "article") {
            return resource as models.DirectoryResource;
        }
        if (resource.type == "group" && resource.resources) {
            for (const nestedResource of resource.resources) {
                if (nestedResource.id === id) {
                    return nestedResource;
                }
            }
        }
    }

    return null;
}
