import useAuth from "@/hooks/useAuth";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

type Props = {
    children: React.ReactNode;
};

export default function RequireAuth(props: Props) {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.me) {
        return (
            <Navigate to="/connect/login" state={{ from: location }} replace />
        );
    }

    return <>{props.children}</>;
}
