import * as errors from "@/lib/api/errors";
import * as models from "@/lib/api/models";
import * as util from "@/lib/api/util";

export async function getResource(resourceKey: string): Promise<string> {
    const params = new URLSearchParams({
        key: resourceKey,
    });
    const [URLstatus, URLresponse] = await util.apiRequest({
        path: "/admin/resource/presigned-get?" + params,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer QWxhZGRpbjpvcGVuIHNlc2FtZQ==`,
        },
    });

    if (URLstatus !== 200) {
        throw new errors.APIHTTPError(
            `Failed getting presigned GET URL from S3 with status code ${URLstatus} in failed API call`,
            URLstatus,
            URLresponse,
        );
    }

    const s3Link = URLresponse as string;

    // Direct HTTP request to the presigned S3 URL
    const response = await fetch(s3Link, {
        method: "GET",
        headers: {
            "Content-Type": "text/markdown",
        },
    });

    // Check if the request was successful
    if (!response.ok) {
        throw new Error(
            `Failed to fetch resource from S3: ${response.statusText}`,
        );
    }

    const markdownContent = await response.text();

    return markdownContent;
}

export async function updateResource(
    resourceKey: string,
    markdownContent: string,
): Promise<void> {
    const params = new URLSearchParams({
        key: resourceKey,
    });

    const [URLstatus, URLresponse] = await util.apiRequest({
        path: "/admin/resource/presigned-put?" + params,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer QWxhZGRpbjpvcGVuIHNlc2FtZQ==`,
        },
    });

    if (URLstatus !== 200) {
        throw new errors.APIHTTPError(
            `Failed getting presigned PUT URL from S3 with status code ${URLstatus} in failed API call`,
            URLstatus,
            URLresponse,
        );
    }

    const s3Link = URLresponse as string;

    // Direct HTTP request to the presigned S3 URL

    const response = await fetch(s3Link, {
        method: "PUT",
        headers: {
            "Content-Type": "text/markdown",
        },
        body: markdownContent,
    });

    // Check if the request was successful

    if (!response.ok) {
        throw new Error(
            `Failed to upload resource to S3: ${response.statusText}`,
        );
    }

    return;
}

function getResourceKey(title: string, id: string): models.LocalizedString {
    //lowercase title and replace spaces with dashes
    const titleSlug = title
        .trim()
        .replace(/[^a-z0-9]/gi, "-")
        .toLowerCase();
    const prefix = "data/article";
    const extension = "md";
    return {
        en: `${prefix}-${titleSlug}-${id}-en.${extension}`, //ids will prevent overwriting of any existing resources with the same exact name
        sp: `${prefix}-${titleSlug}-${id}-sp.${extension}`,
    };
}

export async function getLinkToCreateS3File(
    resourceKey: string,
): Promise<string> {
    const params = new URLSearchParams({
        key: resourceKey,
    });

    const [URLstatus, URLresponse] = await util.apiRequest({
        path: "/admin/resource/presigned-put?" + params,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer QWxhZGRpbjpvcGVuIHNlc2FtZQ==`,
        },
    });

    if (URLstatus !== 200) {
        throw new errors.APIHTTPError(
            `Failed getting presigned PUT URL from S3 with status code ${URLstatus} in failed API call`,
            URLstatus,
            URLresponse,
        );
    }

    const s3Link = URLresponse as string;

    return s3Link;
}

export async function createNewResource(
    newResource: models.NewResourceRequest,
): Promise<models.NewResourceResponse> {
    const id = util.generateUniqueId();
    const resourceKeys = getResourceKey(newResource.title.en, id);
    try {
        const enContentLink = await getLinkToCreateS3File(resourceKeys.en);
        const spContentLink = await getLinkToCreateS3File(resourceKeys.sp);

        const enResource = await fetch(enContentLink, {
            method: "PUT",
            headers: {
                "Content-Type": "text/markdown",
            },
            body: "",
        });

        const spResource = await fetch(spContentLink, {
            method: "PUT",
            headers: {
                "Content-Type": "text/markdown",
            },
            body: "",
        });

        if (!enResource.ok || !spResource.ok) {
            throw new Error(
                `Failed to upload resource to S3: ${enResource.statusText} ${spResource.statusText}`,
            );
        }

        const baseURL = util.extractBaseUrl(enResource.url);

        const completeNewResource: models.NewResourceResponse = {
            id: id,
            title: newResource.title,
            icon: newResource.icon,
            type: newResource.type ?? "article",
            contentUrl: {
                en: `${baseURL}/${resourceKeys.en}`,
                sp: `${baseURL}/${resourceKeys.sp}`,
            },
        };

        return completeNewResource;
    } catch (error) {
        throw new Error(`Failed to create new resource: ${error}`);
    }
}
