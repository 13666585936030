import * as models from "@/lib/api/models";
import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable,
} from "@hello-pangea/dnd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const strings = {
    modifyChild: "Edit Children",
    edit: "Edit",
    modalHeader: "Confirm Deletion of Resource",
    modalBody: (resourceTitle?: string) =>
        `Are you sure you want to delete ${
            resourceTitle ?? "the selected resource"
        }?`,
    delete: "Delete",
    cancelDeletion: "Cancel Deletion",
};

interface BaseResource {
    id: string;
    title: models.LocalizedString;
    type: models.ResourceType;
    resources?: BaseResource[] | null;
}

type Props<T extends BaseResource> = {
    resources: T[];
    setResources: (resources: T[], isDeleting?: boolean) => void;
    onModifyChildOrder?: (resourceId: string) => void;
};

export default function ResourceList<T extends BaseResource>({
    resources,
    setResources,
    onModifyChildOrder,
}: Props<T>) {
    const navigate = useNavigate();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [resourceToDelete, setResourceToDelete] =
        useState<BaseResource | null>(null);
    const handleDragEnd = (result: DropResult) => {
        if (
            !result.destination ||
            result.destination.index === result.source.index
        ) {
            return;
        }

        const reorderedResources = reorder(
            resources,
            result.source.index,
            result.destination.index,
        );

        setResources(reorderedResources);
    };

    const handleResourceDeletion = () => {
        if (!resourceToDelete) {
            return;
        }
        const updatedResources = resources.filter(
            (resource) => resource.id !== resourceToDelete.id,
        );
        setResources(updatedResources, true);
        handleClose();
    };

    const handleClose = () => {
        setShowDeleteModal(false);
        setResourceToDelete(null);
    };

    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="list">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {resources.map((resource, index) => (
                                <Draggable
                                    key={resource.id}
                                    draggableId={resource.id}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className="d-flex align-items-center justify-content-between border border-primary rounded p-2 my-2">
                                                <span className="pt-1 pb-1">
                                                    {resource.title.en}
                                                </span>

                                                <div className="d-flex gap-4">
                                                    <Button
                                                        variant="link"
                                                        className={`text-danger text-decoration-none ${
                                                            resource.type ===
                                                            "group"
                                                                ? ""
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            setResourceToDelete(
                                                                resource,
                                                            );
                                                            setShowDeleteModal(
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        {strings.delete}
                                                    </Button>
                                                    <Button
                                                        variant="link"
                                                        onClick={() =>
                                                            navigate(
                                                                `/edit-resource/${resource.id}`,
                                                            )
                                                        }
                                                        className={`text-primary text-decoration-none ${
                                                            resource.type ===
                                                            "group"
                                                                ? "invisible"
                                                                : ""
                                                        }`}
                                                    >
                                                        {strings.edit}
                                                    </Button>
                                                </div>
                                            </div>
                                            {renderChildResourceInformation(
                                                resource,
                                                onModifyChildOrder,
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Modal
                show={showDeleteModal}
                onHide={handleClose}
                className="pt-5 mt-5"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{strings.modalHeader}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {strings.modalBody(resourceToDelete?.title.en)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        {strings.cancelDeletion}
                    </Button>
                    <Button variant="danger" onClick={handleResourceDeletion}>
                        {strings.delete}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

function renderChildResourceInformation<T extends BaseResource>(
    resource: T,
    onModifyChildOrder?: (resourceId: string) => void,
) {
    if (
        resource.type !== "group" ||
        !resource.resources ||
        !onModifyChildOrder
    ) {
        return null;
    }
    return (
        <div className="d-flex align-items-center justify-content-between border border-warning rounded ms-5 p-2">
            {`${resource.resources.length} child item${
                resource.resources.length > 1 ? "s" : ""
            }`}
            <Button
                variant="link"
                onClick={() => onModifyChildOrder(resource.id)}
                className="text-primary text-decoration-none"
            >
                {strings.modifyChild}
            </Button>
        </div>
    );
}
